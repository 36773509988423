/* Container */
.slick-slider {
    width: 100%;
}

/* Item */
.slider-item {
    width: 99% !important;
    margin-inline: 0.5%;
    height: 75vh;
    background-size: cover;
    background-position: center;
}

/* Content */
.flourish {
    background-image: url('../../public/flourish2.png');
    background-size: 100% 100% !important;
}

.kata {
    background-image: url('../../public/kata.png');
    background-size: 100% 100% !important;
}

.ttr {
    background-image: url('../../public/ttr.png');
    background-size: 100% 100% !important;
}

.unimatch {
    background-image: url('../../public/unimatch.png');
    background-position: top !important;
    background-size: 100% cover !important;
}

.innovogue {
    background-image: url('../../public/innovogue.png');
}

/* Arrows and Dots */
.slick-arrow {
    display: none !important;
}

.slick-dots {
    bottom: -40px;
}

.slick-dots li, .slick-dots li button, .slick-dots li button:before {
    width: 30px;
    height: 30px;
    font-size: 10px;
}

#dark .slick-dots li button:before {
    color: white;
}

/* Project details */
.project-content {
    display: none;
}

.slider-item:hover .project-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.87);
    color: white;
}

.project-content h2, .project-content p {
    animation: slideFromLeft 0.5s ease forwards;
    text-align: center;
    padding-left: 3.5em;
    padding-right: 3.5em;
}

.project-content h2 {
    margin-block-end: 0;
}

.project-content p {
    line-height: 1.5;
    font-weight: regular;
    font-size: large;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-450%);
    }
    to {
        transform: translateX(0%);
    }
}

/* Media queries */
@media screen and (max-width: 1400px) {
    .innovogue {
        background-size: 100% 100% !important;
    }

    .unimatch {
        background-size: 100% 100% !important;
    }
}

@media screen and (max-width: 1024px) {
    .slider-item {
        height: 50vh;
    }
}

@media screen and (max-width: 920px){
    .slider-item{
        height: 40vh;
    }
}

@media screen and (max-width: 768px) {
    .slider-item {
        height: 30vh;
    }
}

@media  screen and (max-width: 500px) {
    .unimatch {
        background-size: 100% 100% !important;
    }
}

@media screen and (max-height: 800px) {
    .innovogue {
        background-size: 100% 100% !important;
    }
}