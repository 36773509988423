/* @import 'variables'; */

.projects {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 15%;
}

.section-title h2 {
    margin-block-start: 0;
    margin-block-end: 1.83em;
    /* Font */
    font-size: 2.5rem;
    font-weight: bolder;
    letter-spacing: 0.02rem;
    /* Gradient */
    background-image: linear-gradient(45deg, #5b13eb, #9999ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}