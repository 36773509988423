/* Content */
.content {
    width: max-content;
    line-height: 40px;
    position: relative;
    overflow: hidden;
    color: black;
    font-size: 2.25rem;
    letter-spacing: 0.08rem;
    padding-top: 1%;
    padding-bottom: 2%;
}

/* Content container */
.content-container {
    height: 40px;
    overflow: hidden;
}

.content-container:before {
    content: "<";
}
 
.content-container:after {
    content: "/>";
    right: 0;
}

.content-container:after, .content-container:before {
    position: absolute;
    top: 10%;
    color: black;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

/* Content list */
.content-container-list {
    margin-top: 0;
    padding-inline-start: 0;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    padding-left: 35px;
    padding-right: 45px;
}

/* Content item */
.content-container-list-item {
    line-height: 40px;
}

/* Dark mode */
#dark .content, #dark .content-container:before, #dark .content-container:after {
    color: #BCCCDC !important;
    background-color: transparent;
}

/* Animation */
@-webkit-keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
}
 
@-webkit-keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
}
 
@keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
}
 
@keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
}

/* Media queries */
/* Reduce font size on smaller screens */
@media (max-width: 780px) {
  .content {
    font-size: 1.5rem;
    padding-top: 3%;
    padding-bottom: 15%;
  }
  
  .content-container-list {
    padding-left: 20px;
    padding-right: 30px;
  }
}

@media (max-width: 375px) {
  .content {
    font-size: 1.25rem;
    padding-top: 3%;
    padding-bottom: 15%;
  }
}