/* Container */
.landing-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding-right: 6%;
}

h1 {
    font-size: 7rem;
    font-weight: normal;
    margin-block-end: 0;
}

/* Socials */
.social-buttons {
    display: flex;
    justify-content: end;
    text-align: center;
}

.button-container {
    border: 2px solid black;
    border-radius: 30px;
    margin-inline-start: 2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
}

.button-container:hover {
    background-color: black;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.button {
    text-decoration: none;
    color: black;
    background-color: transparent;
    letter-spacing: 0.07rem;
}

.button-container:hover .button {
    color: white;
}

/* Dark mode */
#dark .social-buttons {
    background-color: transparent !important;
}

#dark .button-container {
    border: 2px solid transparent;
}

#dark .button-container:hover {
    background-color: transparent;
    border: 2px solid #BCCCDC;
}

#dark h1 {
    z-index: 0;
}

#dark .button {
    color: #BCCCDC;
}

/* Media queries */
/* Center the content on smaller screens */
@media (max-width: 780px) {
    .landing-page {
        align-items: center;
        padding-right: 0;
    }

    h1 {
        font-size: 3.25rem;
    }

    .social-buttons {
        justify-content: center;
    }

    .button-container {
        margin-inline-start: 0.5rem;
        margin-inline-end: 0.5rem;
        padding: 0.25rem 0.5rem;
    }
}
