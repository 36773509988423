/* Navbar container */
.navbar-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.navbar {
    position: fixed;
    top: 3%;
    background-color: transparent;
    transition: background-color 0s ease;
    border-radius: 25px;
    z-index: 1;
}

.navbar.scrolled {
    background-color: rgb(255, 255, 255, 0.6);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-list {
    padding-inline-start: 0;
    margin-block: 0.5rem;
}

/* Navbar items */
.navbar-item {
    display: inline-block;
    margin-inline: 1rem;
    border-radius: 25px; /* For hover effect */
    padding-inline: 1rem;
    padding-block: 0.5rem;
}

.navbar-link {
    text-decoration: none;
    color: black;
    transition: background-color 0.2s ease;
}
  
/* Inner container */
.navbar-item:hover, .navbar-item:focus {
    background-color: white;
    opacity: 1;
}

.navbar-square {
    transition: background-color 0.2s ease;
}

/* Dark mode */
#dark .navbar {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

#dark .navbar-link {
    color: white;
}

#dark .navbar-item:hover, #dark .navbar-item:focus {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Media queries */
/* Reduce spacing between items for smaller screens */
@media (max-width: 400px) {
    .navbar-item {
        margin-inline: 0.25rem;
    }
}