/* Main title */
.experience {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 15%;
}

.experience-title h2 {
    margin-block-start: 0;
    margin-block-end: 0;
    /* Font */
    font-size: 2.5rem;
    font-weight: bolder;
    letter-spacing: 0.02rem;
    /* Gradient */
    background-image: linear-gradient(89.5deg, rgb(52, 178, 165) 1.5%, violet 100.5%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

#dark .experience-title h2 {
    background-image: linear-gradient(89.5deg, rgb(104, 208, 232) 1.5%, rgb(231, 144, 245) 100.5%);
}

/* Experience item */
.experience-content {
    width: 100%;
    display: flex;
    align-items: center;
    margin-block-start: 5rem;
}

.begin {
    margin-block-start: 0 !important;
}

.experience-img {
    width: 30vw;
    height: 25vh;
    margin-inline-start: 6vw;
    margin-inline-end: 6vw;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgb(182, 182, 182);
}

#dark .experience-img {
    box-shadow: 5px 5px 10px black;
}

.experience-details {
    width: 52vw;
    margin-right: 6vw;
}

.experience-heading h2 {
    font-size: 1.75rem;
    margin-block-end: 1em;
}

.experience-details p {
    line-height: 1.5;
}

.experience-details a {
    /* color: #5b13eb; */
    color: black;
    font-weight: bold;
    margin-right: 4px;
}

#dark .experience-details a {
    color: white;
}

/* .experience-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

/* Content */
.watgpt {
    background-image: url('../../public/watgpt.png');
}
#dark .watgpt {
    background-image: url('../../public/watgpt-dark.jpg');
}

.opentext {
    background-image: url('../../public/opentext.png.webp');
}

.venuiti {
    background-image: url('../../public/venuiti.png');
    background-size: 100% 100% !important;
}

.shopify {
    background-image: url('../../public/shopify.png');
}
#dark .shopify {
    background-color: white;
}

.wlu-blockchain {
    background-image: url('../../public/wlu-blockchain.png');
    background-size: 100% 100% !important;
}

.gta {
    background-image: url('../../public/gta.png');
}

/* Media queries */
@media screen and (max-width: 1200px) {
    .experience-img {
        width: 41vw;
        height: 25vh;
    }

    .experience-details {
        width: 41vw;
    }
}

@media screen and (max-width: 768px) {
    .experience-content {
        flex-direction: column;
        margin-block-start: 2.5rem;
    }

    .experience-img {
        width: 80vw;
        height: 22.5vh;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin-block-end: 1em;
    }

    .experience-details {
        width: 80vw;
        margin-right: 0;
    }
}