/* Container */
.about-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    padding-right: 15%;
}

/* Profile image */
.profile-pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding-right: 7.5%;
}

.profile-img {
    background-image: url('../../public/profilePic.jpg');
    background-size: cover;
    background-position: center;
    width: 300px;   /* to be changed */
    height: 300px;
    border-radius: 50%;
    box-shadow: 5px 5px 10px white;
}

/* About heading */
.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-content h2 {
    margin-block-start: 0;
    /* Font */
    font-size: 2.5rem;
    font-weight: bolder;
    letter-spacing: 0.02rem;
    /* Gradient */
    background-image: linear-gradient(45deg, #5211d4, #b3b3ff, white 90%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

#dark .about-content h2 {
    background-image: linear-gradient(45deg, #9d71f4, #efe7fd, #efe7fd);
}

.alternate-h2 {
    display: none;
}

/* About text */
.about-content p {
    line-height: 1.5;
    font-weight: regular;
    margin-block-end: 1em;
}

.about-content ul {
    list-style-type: none;
    padding-inline-start: 0;
}

.about-content li {
    font-weight: regular;
    line-height: 1.5;
    margin-bottom: 1em;
}

/* Media queries */
/* lg */
@media (max-width: 1023px) {
    .profile-img {
        width: 250px;        
        height: 250px;
    }
    
    .about-container {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media (max-width: 800px) {
    .about-content h2, .alternate-h2 {
        font-size: 2rem;
    }
}

@media (max-width: 375px) {
    .about-content h2, .alternate-h2 {
        font-size: 1.65rem;
    }
}

/* md, sm */
@media (max-width: 767px) {
    .about-container {
        width: 80%;
        flex-direction: column;
        padding-left: 10%;
        padding-right: 10%;
    }

    .about-content h2 {
        display: none;
    }
    
    .alternate-h2 {
        display: block;
        padding-left: 10%;
        /* Font */
        font-weight: bolder;
        letter-spacing: 0.02rem;
        /* Gradient */
        /* background-image: linear-gradient(45deg, #400da5, #e6e6ff); */
        background-image: linear-gradient(45deg, #5b13eb, #ffffff);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    .profile-pic {
        align-items: center;
    }
}

/* Manage icons */
@media (max-width: 700px) {
    .icon {
        font-size: 0.625em !important;
    }
}

@media (min-width: 1360px) {
    .icon {
        font-size: 1.25em !important;
    }
}

@media (min-width: 1900px) {
    .icon {
        font-size: 1.75em !important;
    }
}

/* Dark mode */
#dark .profile-img,  #dark .about-content h2, #dark .about-content p {
    z-index: 0;
}
