/* Container */
.projects-layout {
    width: 88%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Item */
.project {
    background-size: cover;
    background-position: center;
    margin-block-end: 1%;
    cursor: pointer;
}

/* Half area */
.half {
    width: 48.5%;
    height: 75vh;
}

.one {
    margin-inline-end: 0.5%;
}

.two {
    margin-inline-start: 0.5%;
}

/* Full area */
.full {
    width: 98%;
    height: 80vh;
}

/* Content */
.solana-rewards {
    background-image: url('../../public/solana-rewards.png');
}

.lacs {
    /* background-color: purple; */
    background-image: url('../../public/lacs.png');
    background-size: 100% 100% !important;
}

.blockaoke {
    background-image: url('../../public/blockaoke.png');
}

.symptomed {
    background-image: url('../../public/symptomed.png');
}

.pharmahacks {
    background-image: url('../../public/pharmahacks.png');
}

.guido {
    background-image: url('../../public/guido.jpg');
}

.eq {
    background-image: url('../../public/eq.png');
    background-position: left !important;
}

/* Project details */
.project-content {
    display: none;
}

.project:hover .project-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.87);
    color: white;
}

.project-content h2, .project-content p, .project-content a {
    animation: slideFromLeft 0.5s ease forwards;
    text-align: center;
    padding-left: 3.5em;
    padding-right: 3.5em;
}

.project-content h2 {
    margin-block-end: 0;
}

.project-content p {
    line-height: 1.5;
    font-weight: regular;
    font-size: large;
}

.project-content a {
    text-decoration: none;
    color: white;
    padding: 0.5em 1em;
    margin-top: 1em;
}

.project-content a:hover {
    color: hotpink;
}

.project-content a .icon {
    margin-inline-end: 0.5em;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-450%);
    }
    to {
        transform: translateX(0%);
    }
}

/* Media queries */
@media screen and (max-width: 1024px) {
    .half, .full {
        height: 50vh;
    }
}

@media screen and (max-width: 920px){
    .full{
        height: 40vh;
    }
}

@media screen and (max-width: 768px) {
    .half, .full {
        height: 30vh;
    }

    .project-content h2, .project-content p {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
    }

    .project-content h2 {
        font-size: 0.5rem !important;
    }

    .project-content p {
        font-size: 8px !important;
    }

    .project-content a {
        font-size: 8px !important;
        margin-top: 0.5em !important;
    }
}