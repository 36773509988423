/* @import 'variables'; */

/* Footer content with lines */
.footer {
    bottom: 0;
}

/* Footer content */
.footer-content {
    display: flex;
    justify-content: space-evenly;
    color: #5b13eb;
    padding-block-end: 2%;
}

/* Spotify and Copyright */
.spotify-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 100px;
}

.spotify-logo {
    color: #9999ff;
    width: 40px;
    height: 40px;
    padding: 0.5rem;
}

.repeat-text {
    font-size: smaller;
    margin-block-end: 0.25rem;
}

.song-text {
    font-size: medium;
    font-weight: bolder;
    margin-block-start: 0;
}

.name-text {
    font-weight: bolder;
}

/* Socials */
.right-content p {
    font-weight: bolder;
}

.right-content ul {
    list-style: none;
    padding: 0;
}

.right-content ul li {
    margin-top: 2.5rem;
}

.right-content ul li a {
    text-decoration: none;
    color: inherit;
}

/* Lines content */
.lines-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-block-end: 10%;
}

.line {
    width: calc(100% / 24);
    height: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

/* Internal line */
.line-inner {
    width: 2.5px;
    height: 15px;
    background-color: #9999ff;
    transition: height 0.05s ease;
    position: absolute;
    bottom: 0;
}

.line-inner.middle-line-inner {
    height: 25px;
    background-color: #5b13eb;
}

.line.active .line-inner {
    height: 50px;
}

.line.active-adjacent .line-inner {
    height: 37px;
}

/* Icons content */
.icons-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-block-end: 1%;
}

.icon-box {
    width: calc(100% / 24);
    height: 4.166666666666667vw;
    border-radius: 50%;
    background-color: #5b13eb;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.05s ease;
    visibility: hidden;
}

/* Dark mode */
#dark .spotify-box {
    background-color: rgba(255, 255, 255, 0.2);
    color: #9999ff;
}

#dark .right-content p {
    color: #9999ff;
}

#dark .right-content ul, #dark .name-text {
    color: white;
}

#dark .line-inner {
    background-color: rgba(255, 255, 255, 0.5);
}

#dark .line-inner.middle-line-inner{
    background-color: #9999ff;
}

#dark .icon-box {
    background-color: #9999ff;
    color: black;
}

/* Media queries */
@media (max-width: 636px) {
    .footer-content {
        flex-direction: column-reverse;
        align-items: center;
    }
    
    .spotify-box {
        display: none;
    }

    .right-content {
        text-align: center;
    }

    .name-text {
        margin-top: 5rem;
    }
}

@media (max-width: 374px) {
    .name-text, .right-content {
        font-size: 0.8rem;
    }
}