/* Wrapper */
.animatedwrapper {
    width: 100%;
    height: 2000px;
    position: absolute;
    left: 0px;
    top: -100px;
    overflow: hidden;
    z-index: -1;
    isolation: isolate;
}

/* Dots */
.dot {
    --size: 700px;
    /* Only show the animated dots for browsers that support the required features */
    display: none;
    position: absolute;
    left: calc(var(--size) / 3 * -1);
    top: calc(var(--size) / 3 * -1);
    width: var(--size);
    height: var(--size);
    mix-blend-mode: soft-light;
    border-radius: var(--size);
    filter: blur(calc(var(--size) / 6));
    animation: dot-animation ease-in-out 20s infinite;
    will-change: transform;
}
  
@supports (mix-blend-mode: soft-light) and (filter: blur(1px)) {
    .animatedwrapper {
        background: none;
    }
    .dot {
        display: block;
    }
}
  
/* Dot colors */
.dot--one {
    background: #ed2a24;    
}

.dot--two {
    background: #f48937;
    animation-delay: -7s;
}

.dot--three {
    background: #bd3690;
    animation-delay: -14s;
}
  
.dot--four {
    background: #9999ff;
    --size: 1000px;
    position: absolute;
    left: calc(var(--size) / 3 * -1);
    top: calc(var(--size) / 3 * 0.7);
    width: var(--size);
    height: var(--size);
    mix-blend-mode: soft-light;
    border-radius: var(--size);
    filter: blur(calc(var(--size) / 6));
    will-change: transform;
}
  
/* Animation */
@keyframes dot-animation {
    0% {
      transform: translate(0, 0);
    }
    33% {
      transform: translate(calc(var(--size) / 1.5), 0);
    }
    66% {
      transform: translate(0, calc(var(--size) / 1.5));
    }
    100% {
      transform: translate(0, 0);
    }
}

/* Media queries */
/* Adjust the size of the dots */
/* sm */
@media only screen and (max-width: 600px) {
    .animatedwrapper {
        top: 0px;
    }
    .dot {
        --size: 200px; 
    }
    .dot--four {
        --size: 300px;
    }
}
  
/* Dark mode */
#dark {
    background-color: black;
    color: white !important;
    z-index: 0;
}

#dark .animatedwrapper {
    z-index: 0;
}
